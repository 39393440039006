<template>
  <v-app>
    <v-main>
      <ShipmentRequests/>
    </v-main>
  </v-app>
</template>

<style>
/* .v-application {
  font-family: 'Raleway' !important;
} */
</style>

<script>
import ShipmentRequests from './components/ShipmentRequests';

export default {
  name: 'App',

  components: {
    ShipmentRequests,
  },

  data: () => ({
    //
  }),
};
</script>
