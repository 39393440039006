<template>
  <v-container>

    <v-container
      v-if="!showForm"
      class="pa-0"
    >
      <v-banner
        v-for="notice in notices"
        :key="notice.id"
        class="mb-2"
        color="primary"
        elevation="1"
      >
        <v-icon
          slot="icon"
          color="white"
          size="36"
        >
          mdi-information-outline
        </v-icon>
        {{ notice.notice }}
      </v-banner>
    </v-container>

    <v-img
      class="d-flex justify-center mt-6 mb-5"
      :src="logo"
      contain
      height="75"
      />

    <h2
      class="d-flex justify-center mb-4"
    >
      Shipment Requests
    </h2>

    <v-progress-linear
      v-if="!showForm && !shipmentRequest"
      style="width: 200px;"
      class="mt-10 mx-auto"
      indeterminate
      color="black"
    ></v-progress-linear>

    <v-container
      v-if="showForm"
      class="d-flex justify-center"
    >
      <v-sheet
        :width="$vuetify.breakpoint.xs ? '100%' : 300"
        class="pa-5"
      >

        <v-form
          v-model="formValid"
          ref="form"
          @submit.prevent="submitForm"
        >
          <v-text-field
            v-model="formEmail"
            label="Email Address"
            required
            :rules="[v => !!v || 'This field is required', v => /.+@.+/.test(v) || 'Must be a valid e-mail address']"
          ></v-text-field>
          <v-text-field
            v-model="formId"
            label="ID Number / Exporters Code"
            required
            :rules="[v => !!v || 'This field is required']"
          ></v-text-field>
          <v-text-field
            v-model="formUrn"
            label="Shipment Request URN"
            required
            :rules="[v => !!v || 'This field is required']"
          ></v-text-field>
          <v-btn
            type="submit"
            block
            tile
            depressed
            class="black white--text mt-2"
          >
            Submit
          </v-btn>
        </v-form>

      </v-sheet>
    </v-container>

    <v-card
      v-if="!showForm && shipmentRequest"
      class="mb-4 elevation-0"
    >
      <v-card-title>
        URN: {{ queryUrn }}
      </v-card-title>
      <v-data-table
        :headers="shipmentRequestTableHeaders"
        :items="[shipmentRequest]"
        :hide-default-footer="true"
        disable-pagination
        :loading="fetchingRequest"
        loading-text="Loading... Please wait"
        class="elevation-0"
      >
        <v-progress-linear
          v-show="fetchingRequest"
          slot="progress"
          color="black"
          indeterminate
        ></v-progress-linear>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatDateTime(item.createdAt) }}
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
          {{ formatDateTime(item.updatedAt) }}
        </template>
        <template v-slot:[`item.fileName`]="{ item }">
          <v-btn
            v-if="item.fileUrl"
            :href="`${item.fileUrl}`"
            x-small
            tile
            depressed
            class="black white--text"
          >
            Download
          </v-btn>
        </template>
        <template v-slot:[`item.trackShipment`]="{ item }">
          <v-btn
            v-if="item.trackingNumber"
            :href="`https://tracking.artistadmin.co.za/${item.trackingNumber}`"
            target="_blank"
            x-small
            tile
            depressed
            class="black white--text"
            >
            Track
          </v-btn>
        </template>
        <template v-slot:[`item.emailUs`]="{ item }">
          <v-btn
            :href="`mailto:shipit@artistadmin.co.za?subject=Enquiry on ${item.urn} - ${item.name}`"
            x-small
            tile
            depressed
            class="black white--text"
            >
            Email Us
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-card
      v-if="!showForm && shipmentRequest"
      class="mb-4 elevation-0"
    >
      <v-card-title>
        Pending Requests
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="pendingRequestsSearch"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="pendingRequestsTableHeaders"
        :items="pendingRequests"
        :hide-default-footer="true"
        disable-pagination
        :loading="fetchingRequests"
        loading-text="Loading... Please wait"
        no-data-text="No pending requests"
        :search="pendingRequestsSearch"
        class="elevation-0"
      >
        <v-progress-linear
          v-show="fetchingRequests"
          slot="progress"
          color="black"
          indeterminate
        ></v-progress-linear>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatDateTime(item.createdAt) }}
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
          {{ formatDateTime(item.updatedAt) }}
        </template>
        <template v-slot:[`item.fileName`]="{ item }">
          <v-btn
            v-if="item.fileUrl"
            :href="`${item.fileUrl}`"
            x-small
            tile
            depressed
            class="black white--text"
          >
            Download
          </v-btn>
        </template>
        <template v-slot:[`item.emailUs`]="{ item }">
          <v-btn
            :href="`mailto:shipit@artistadmin.co.za?subject=Enquiry on ${item.urn} - ${item.name}`"
            x-small
            tile
            depressed
            class="black white--text"
            >
            Email Us
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-card
      v-if="!showForm && shipmentRequest"
      class="mb-4 elevation-0"
    >
      <v-card-title>
        Completed Requests
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="completedRequestsSearch"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="completedRequestsTableHeaders"
        :items="completedRequests"
        :hide-default-footer="true"
        disable-pagination
        :loading="fetchingRequests"
        loading-text="Loading... Please wait"
        no-data-text="No completed requests"
        :search="completedRequestsSearch"
        class="elevation-0"
      >
        <v-progress-linear
          v-show="fetchingRequests"
          slot="progress"
          color="black"
          indeterminate
        ></v-progress-linear>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatDateTime(item.createdAt) }}
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
          {{ formatDateTime(item.updatedAt) }}
        </template>
        <template v-slot:[`item.fileName`]="{ item }">
          <v-btn
            v-if="item.fileUrl"
            :href="`${item.fileUrl}`"
            x-small
            tile
            depressed
            class="black white--text"
          >
            Download
          </v-btn>
        </template>
        <template v-slot:[`item.trackShipment`]="{ item }">
          <v-btn
            v-if="item.trackingNumber"
            :href="`https://tracking.artistadmin.co.za/${item.trackingNumber}`"
            target="_blank"
            x-small
            tile
            depressed
            class="black white--text"
            >
            Track
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
        
    <p
      v-if="shipmentRequest || showForm"
      class="text-caption text-center mt-8"
    >
      Copyright &copy; Artist Admin {{ (new Date()).getFullYear() }}
    </p>
  </v-container>
</template>

<style>
  .v-banner {
    color: white !important;
  }
  .v_banner>.v-banner__wrapper {
    padding-top: 8 !important;
    padding-bottom: 8 !important;
  }
  .v-data-table-header th {
    white-space: nowrap;
  }
  /* .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    font-size: 10px;
  } */
</style>

<script>
import moment from 'moment'
import logo from '../assets/logo-artist-admin.png'

export default {
  name: 'ShipmentRequests',

  data: () => ({
    formValid: false,
    notices: [],
    logo,
    formEmail: null,
    formId: null,
    formUrn: null,
    queryEmail: null,
    queryId: null,
    queryUrn: null,
    fetchingRequest: false,
    shipmentRequest: null,
    shipmentRequestTableHeaders: [],
    fetchingRequests: false,
    pendingRequests: [],
    pendingRequestsTableHeaders: [
        { text: 'URN', value: 'urn' },
        { text: 'Item Name', value: 'name', width: 200 },
        { text: 'Request Date', value: 'createdAt', width: 155 },
        { text: 'Status', value: 'status' },
        { text: 'Recipient Name', value: 'recipientName' },
        { text: 'Last Updated', value: 'updatedAt', width: 155 },
        { text: 'Days In Status', value: 'daysInStatus' },
        { text: 'Quote/Invoice Numbers', value: 'documentNumbers' },
        { text: 'Invoice File', value: 'fileName' },
        { text: '', value: 'emailUs' }
    ],
    pendingRequestsSearch: '',
    completedRequests: [],
    completedRequestsTableHeaders: [
        { text: 'URN', value: 'urn' },
        { text: 'Item Name', value: 'name', width: 200 },
        { text: 'Request Date', value: 'createdAt', width: 155 },
        { text: 'Recipient Name', value: 'recipientName' },
        { text: 'Last Updated', value: 'updatedAt', width: 155 },
        { text: 'Quote/Invoice Numbers', value: 'documentNumbers' },
        { text: 'Invoice File', value: 'fileName' },
        { text: 'Date Shipped', value: 'shippedAt', width: 120 },
        { text: 'Tracking Number', value: 'trackingNumber' },
        { text: 'Courier', value: 'courier' },
        { text: '', value: 'trackShipment' }
    ],
    completedRequestsSearch: '',
  }),

  computed: {
    showForm() {
      return !this.queryEmail || !this.queryId || !this.queryUrn
    }
  },
  
  methods: {
    formatDate(date) {
        return moment(date).format("DD MMM YYYY")
    },
    formatDateTime(date) {
        return moment(date).format("DD MMM YYYY HH:mm")
    },
    submitForm() {
      if(!this.formValid) return this.$refs.form.validate()
      return window.location.replace(`/?email=${this.formEmail}&id=${this.formId}&urn=${this.formUrn}`)
    },
    getNotices() {
      fetch(`${process.env.VUE_APP_ARTIST_ADMIN_TRACKING_API_URL}/notices`)
      .then(response => response.json())
      .then(data => this.notices = data)
    },
    getShipmentRequest() {
      this.fetchingRequest = true
      fetch(`${process.env.VUE_APP_ARTIST_ADMIN_TRACKING_API_URL}/shipment-requests/?email=${this.queryEmail}&id=${this.queryId}&urn=${this.queryUrn}`)
      .then(response => response.json())
      .then(shipmentRequest => {
        shipmentRequest.recipientName = `${shipmentRequest.recipientFirstName} ${shipmentRequest.recipientLastName}`
        this.fetchingRequest = false
        this.shipmentRequest = shipmentRequest
        this.shipmentRequest.status === "Done" ? this.shipmentRequestTableHeaders = this.completedRequestsTableHeaders : this.shipmentRequestTableHeaders = this.pendingRequestsTableHeaders
        this.getNotices()
        this.getShipmentRequests()
      })
    },
    getShipmentRequests() {
      this.fetchingRequests = true
      fetch(`${process.env.VUE_APP_ARTIST_ADMIN_TRACKING_API_URL}/shipment-requests/?email=${this.queryEmail}&id=${this.queryId}`)
      .then(response => response.json())
      .then(shipmentRequests => {
        for(let shipmentRequest of shipmentRequests) { shipmentRequest.recipientName = `${shipmentRequest.recipientFirstName} ${shipmentRequest.recipientLastName}` }
        this.fetchingRequests = false
        this.pendingRequests = shipmentRequests.filter(s => s.urn !== this.queryUrn && s.status !== "Done").sort(
          (objA, objB) => (new Date(objB.createdAt)) - (new Date(objA.createdAt))
        )
        this.completedRequests = shipmentRequests.filter(s => s.urn !== this.queryUrn && s.status === "Done").sort(
          (objA, objB) => (new Date(objB.createdAt)) - (new Date(objA.createdAt))
        )
      })
    },
  },

  created() {
    let urlParams = new URLSearchParams(window.location.search);
    this.queryEmail = urlParams.get('email')
    this.queryId = urlParams.get('id')
    this.queryUrn = urlParams.get('urn')
    if(!this.showForm) {
      this.getShipmentRequest()
    }
  },
}
</script>